import React from "react"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Paragraph } from "../components/text"
import { Screen } from "../responsive"
import { theme } from "../components/theme"
import { Css } from "../components/css"
import { LaumesLogoTextOnly } from "../components/LaumesLogoTextOnly"

const Heading = (props: { children: React.ReactNode }) => {
  return (
    <h2
      css={css`
        font-size: 2.4rem;
        line-height: 1.3rem;
        font-family: Roboto, sans-serif;
        font-weight: 200;
        letter-spacing: 0.5px;
        max-width: 650px;
      `}
      {...props}
    ></h2>
  )
}

const Section = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <section
      css={{
        margin: `5rem 0`,
      }}
      {...props}
    ></section>
  )
}

const SectionTextContainer = props => {
  return <div css={{ padding: `0 2rem` }} {...props}></div>
}

const ResponsiveImage = props => {
  return (
    <img
      src=""
      alt=""
      css={{
        width: "100%",
        minHeight: 400,
        height: "100%",
        objectFit: "cover",
      }}
      {...props}
    />
  )
}

function Highlighted(props) {
  return (
    <strong
      {...props}
      css={{
        background: "#ff005436",
      }}
    ></strong>
  )
}

const IndexPage = ({ data }) => {
  const sketches = [
    "sketch1",
    "sketch2",
    "sketch3",
    "sketch4",
    "sketch5",
    "sketch6",
    "sketch7",
    "sketch8",
  ]
  return (
    <Layout>
      <SEO />
      <main
        css={{
          position: "relative",
        }}
      >
        {/* <Splash /> */}
        <section
          css={{
            marginBottom: "3rem",
            position: "relative",
            height: "calc(100vh - var(--topmenuHeight))",
          }}
        >
          <Img
            loading="eager"
            backgroundColor="#9a9280"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            fluid={data.mainPhoto.childImageSharp.fluid}
            alt={"Laumes - architekturos studija"}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background:
                "linear-gradient(0deg, rgb(24 23 41) 0%, rgba(0, 0, 0, 0.4) 18%, rgba(255, 255, 255, 0) 47%)",
            }}
          />
          <div
            style={{
              color: "white",
              textAlign: "center",
              position: "absolute",
              bottom: "0",
              left: "50%",
              transform: `translate(-50%, 0)`,
            }}
          >
            <LaumesLogoTextOnly
              css={{ minWidth: 240, width: "50vw", maxWidth: 680 }}
              fill="white"
            />
            <div
              css={{
                fontSize: "1rem",
                maxWidth: "auto",
                letterSpacing: "0.5rem",
                margin: "1rem auto 5rem",
                [Screen.XS]: {
                  margin: "1rem auto 3rem",
                },
              }}
            >
              KAM NAMAI, O KAM PASAKA
            </div>
          </div>
        </section>

        <Section
          css={{
            width: "90vw",
            margin: `8rem auto`,
            minHeight: "35vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            css={{
              textAlign: "center",
              lineHeight: 1.75,
              minWidth: 220,
              width: "50vw",
              maxWidth: 640,
              margin: "0 auto",
              fontSize: "x-large",
            }}
          >
            Projektuojame{" "}
            <Highlighted>individualius gyvenamuosius namus</Highlighted>,
            kuriame <Highlighted>visuomeninių</Highlighted> ir{" "}
            <Highlighted>privačių</Highlighted> objektų{" "}
            <Highlighted>interjerus</Highlighted>
          </p>
        </Section>
        <Section>
          <div
            css={{
              margin: "3rem 0",
              display: "grid",
              gridGap: "1rem",
              padding: "1rem",
              gridTemplateColumns: `repeat(2, 1fr)`,
              [Screen.S]: {
                gridTemplateColumns: `repeat(4, 1fr)`,
              },
              ...Css.container,
            }}
          >
            {sketches.map(sk => (
              <Img
                loading="lazy"
                key={`a${sk}`}
                fluid={data[sk].childImageSharp.fluid}
                alt="Interjero eskizas - Laumes"
                css={{
                  height: "300px",
                  borderRadius: 8,
                }}
              />
            ))}
          </div>
        </Section>
        <Section
          css={{
            ...Css.responsiveSection,
            ...Css.container,
            minHeight: "75vh",
          }}
        >
          <SectionTextContainer>
            <Heading>Darbo praktika</Heading>
            <p css={{ maxWidth: "650px" }}>
              Mūsų projektai pagrįsti meistriškumu detalėms, kurios kuria
              vartotojiškumo eroje gajaus trumpalaikio poveikio. Laumės kuria
              ilgaamžius ir tvarius sprendimus.
            </p>
          </SectionTextContainer>
          <Img
            fluid={data.interiorOne.childImageSharp.fluid}
            alt="Laumes - interjero dizaino projektas"
            css={{ height: "100%" }}
          />
        </Section>
        <Section
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: `3rem auto`,
            textAlign: "center",
            maxWidth: "900px",
            width: "auto",
          }}
        >
          <Heading>Architektūra</Heading>
          <p css={{ maxWidth: "950px" }}>
            Glaudžiai bendradarbiaudamos su patikimais profesionalais,
            atliepiame klientų poreikiams kuriant praktiškus ir ekonomiškai
            efektyvius statinius. Jūsų istorija ir svajonės tampa gairėmis mūsų
            vaizduotei.
          </p>
          <p css={{ maxWidth: "950px" }}>
            Greta to, atsižvelgiame į kontekstą, tradicijas, fizinius pojūčius
            per medžiagas ir šviesą. Taip pasiekiame savo architektūros kalbą,
            kuri nešykšti estetikos, tačiau sugeba pasipriešinti laikui ir
            trumpalaikei madai.
          </p>
        </Section>
        <Img
          fluid={data.exteriorOne.childImageSharp.fluid}
          alt="A corgi smiling happily"
          css={{ marginBottom: "3rem" }}
        />
        <Section
          css={{
            ...Css.responsiveSection,
            ...Css.container,
            height: "90vh",
          }}
        >
          <SectionTextContainer>
            <Heading>Interjero Dizainas</Heading>
            <p css={{ maxWidth: "650px" }}>
              Prijaučiant minimalistiniam interjerui, mes norime sužadinti
              individualaus naratyvo svarbą. Mūsų meilė natūralioms medžiagoms
              ir ekologijai paįvairinama per spalvą ir funkcionalumą.
              Paprastumu, praktiškumu ir individualia gyventojų istorija
              paremtas dizainas sukuria unikalų ir jautrų interjerą.
            </p>
          </SectionTextContainer>
          <Img
            fluid={data.interiorTwo.childImageSharp.fluid}
            alt="Laumes - buto virtuves interjero dizaino projektas"
            css={{ height: "100%" }}
          />
        </Section>
      </main>
    </Layout>
  )
}

function Splash() {
  return (
    <section
      id="splash"
      css={css`
        position: relative;
        height: calc(100vh - 24px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        position: relative;
      `}
      style={{ backgroundImage: `url(${splashBg})` }}
    >
      <div
        css={css`
          padding-left: 3rem;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 24px;
        `}
      >
        <p css={{ margin: "0", maxWidth: "650px" }}>
          Architektura | Interjero dizainas
        </p>
        <h1
          css={css`
            font-size: 1.9rem;
            line-height: 1.3rem;
            font-family: ${theme.font.primary};
            font-weight: 700;
            max-width: 500px;
            text-align: right;
            margin: 0;
          `}
        >
          Laumės
        </h1>
      </div>
    </section>
  )
}

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const sketchImage = graphql`
  fragment sketchImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    mainPhoto: file(relativePath: { eq: "mainPhoto.webp" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logoImage: file(relativePath: { eq: "logoImage.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    exteriorOne: file(relativePath: { eq: "exterior1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interiorOne: file(relativePath: { eq: "interior1.jpg" }) {
      ...fluidImage
    }

    interiorTwo: file(relativePath: { eq: "interior2.jpg" }) {
      ...fluidImage
    }

    sketch1: file(relativePath: { eq: "sketches/1.jpg" }) {
      ...sketchImage
    }

    sketch2: file(relativePath: { eq: "sketches/2.jpg" }) {
      ...sketchImage
    }

    sketch3: file(relativePath: { eq: "sketches/3.jpg" }) {
      ...sketchImage
    }

    sketch4: file(relativePath: { eq: "sketches/4.jpg" }) {
      ...sketchImage
    }

    sketch5: file(relativePath: { eq: "sketches/5.jpg" }) {
      ...sketchImage
    }

    sketch6: file(relativePath: { eq: "sketches/6.jpg" }) {
      ...sketchImage
    }

    sketch7: file(relativePath: { eq: "sketches/7.jpg" }) {
      ...sketchImage
    }

    sketch8: file(relativePath: { eq: "sketches/8.jpg" }) {
      ...sketchImage
    }
  }
`
const gradient =
  "linear-gradient(0deg, rgb(17 15 39) 0%, rgb(0 0 0 / 40%) 18%, rgba(255,255,255,0) 47%)"
