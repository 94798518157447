import * as React from "react"

export function LaumesLogoTextOnly(props) {
  return (
    <svg
      viewBox="0 0 462.862 85.46"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a" clipPathUnits="userSpaceOnUse">
          <path d="M0 500h500V0H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#prefix__a)"
        transform="matrix(1.33333 0 0 -1.33333 -103.24 274.352)"
      >
        <path d="M88.899 197.26v-43.625h23.06v-3.005H85.295v46.63zM162.796 169.753l-10.049 21.965-10.014-21.965zm-9.027 27.839l21.556-46.962h-3.782l-7.375 16.122h-22.802l-7.349-16.122h-3.783l21.556 46.962zM232.196 197.26h3.605v-25.437c0-6.965-1.815-12.339-5.442-16.122-3.627-3.782-8.32-5.67-14.086-5.67-5.74 0-10.424 1.867-14.05 5.606-3.628 3.74-5.44 8.854-5.44 15.353v26.27h3.636v-26.136c0-5.563 1.475-9.915 4.425-13.05 2.946-3.14 6.758-4.706 11.43-4.706 4.717 0 8.551 1.583 11.502 4.753 2.945 3.174 4.42 7.673 4.42 13.503zM288.6 175.601l17.713 21.66h3.648v-46.63h-3.635v41.354l-17.743-21.797-17.804 21.797V150.63h-3.605v46.63h3.605zM414.428 194.922v-3.972c-4.05 2.381-7.53 3.571-10.446 3.571-2.803 0-5.145-.849-7.025-2.553-1.881-1.704-2.821-3.813-2.821-6.327 0-1.802.557-3.425 1.669-4.873 1.113-1.445 3.437-2.834 6.974-4.17 3.54-1.338 6.12-2.45 7.746-3.339 1.622-.888 3.002-2.182 4.136-3.873 1.139-1.69 1.704-3.88 1.704-6.573 0-3.584-1.312-6.61-3.938-9.078-2.626-2.47-5.831-3.705-9.613-3.705-3.873 0-7.854 1.354-11.947 4.071v4.24c4.516-3.316 8.432-4.973 11.748-4.973 2.756 0 5.089.893 6.99 2.687 1.903 1.79 2.856 3.99 2.856 6.59 0 1.984-.591 3.77-1.768 5.361-1.182 1.587-3.571 3.075-7.177 4.46-3.601 1.384-6.175 2.497-7.724 3.342-1.548.845-2.864 2.052-3.942 3.618-1.078 1.57-1.621 3.588-1.621 6.056 0 3.424 1.315 6.344 3.942 8.76 2.622 2.41 5.783 3.617 9.479 3.617 3.623 0 7.22-.979 10.778-2.937M345.893 153.636v18.924h21.263v3.036h-21.263v18.658h21.827v3.006h-9.763v2.928h-6.08v-2.928h-9.59v-46.63h25.967v3.006z" />
      </g>
    </svg>
  )
}
